<template>
    <div>
        <div class="row mb-3">
            <div class="col-6 d-flex align-items-center">
                <h2 class="m-0 font-weight-normal">Schema Fields</h2>
            </div>
            <div class="col-6 text-right">
                <mercur-button class="btn btn-primary" key="add-field" v-if="!systemRestricted" @click.native="openAddNewFieldDialog">Add new field</mercur-button>
            </div>
        </div>
        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th>&nbsp;</th>
                        <th>Field Name</th>
                        <th>Field Title</th>
                        <th>Field Type</th>
                        <th>Default Value</th>
                        <th>Restrict Multiple Values</th>
                        <th>Multiple Values</th>
                        <th>Values</th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(field, index) in value" :key="JSON.stringify(field)">
                        <td><a class="text-blue" @click="removeField(index)">remove</a></td>
                        <td>{{field.name}}</td>
                        <td>{{field.title}}</td>
                        <td>{{field.type}}</td>
                        <td>{{field.default}}</td>
                        <td>{{field.array}}</td>
                        <td>{{field.multipleValues}}</td>
                        <td><span v-for="(value, i) in field.values" :key="i">{{value}}, </span></td>
                        <td><a class="text-blue" @click="openFieldEditDialog(index)">edit</a></td>
                    </tr>
                </tbody>
            </table>
        </div>

        <mercur-dialog :is-open.sync="isFieldDetailsDialogActive">
            <account-configuration-schema-field
                :value="editingIndex === null ? undefined : value[editingIndex]"
                @input="handleSchemaInputEvent"
                :system-restricted="systemRestricted"
            ></account-configuration-schema-field>
        </mercur-dialog>
    </div>
</template>

<script>
import AccountConfigurationSchemaField from './AccountConfigurationSchemaField'
export default {
    name: 'AccountConfigurationSchemaCollection',
    components: { AccountConfigurationSchemaField },
    props: {
        value: {
            default: [],
        },
        systemRestricted: {
            default: false,
        },
    },
    data () {
        return {
            isFieldDetailsDialogActive: false,
            editingIndex: null,
        }
    },
    methods: {
        openAddNewFieldDialog () {
            this.isFieldDetailsDialogActive = true
        },
        removeField (fieldIndex) {
            this.value.splice(fieldIndex, 1)
        },
        handleSchemaInputEvent (newValues) {
            this.isFieldDetailsDialogActive = false

            if (this.editingIndex === null) {
                this.value.push(newValues)

                return
            }
            const value = JSON.parse(JSON.stringify(this.value))
            this.$set(value, this.editingIndex, newValues)
            this.$emit('input', value)
        },
        openFieldEditDialog (editingIndex) {
            this.editingIndex = editingIndex
            this.isFieldDetailsDialogActive = true
        },
    },

}
</script>
