<template>
    <div>
        <h2 class="mt-1 font-weight-normal">Add/edit Field</h2>
        <mercur-input :class="getValidationClass($v, 'name')" v-model="form.name" :disabled="systemRestricted">
            Field name*
            <template #note>
                <span class="form-error" v-if="!$v.form.name.required">Field name is required</span>
                <span class="form-error" v-if="!$v.form.name.isCamelCase">Field name needs to be camelCased</span>
                <span class="form-error" v-if="!$v.form.name.minLength">Field name must have at least two characters</span>
            </template>
        </mercur-input>

        <mercur-input :class="getValidationClass($v, 'title')" v-model="form.title" :disabled="systemRestricted">
            Field title*
            <template #note>
                <span class="form-error" v-if="!$v.form.title.required">Field title is required</span>
            </template>
        </mercur-input>

        <div class="mb-3">
            <pretty-select
                placeholder="Field Type"
                :required="true"
                :options="fieldTypes"
                v-model="form.type"
                :disabled="systemRestricted">
            </pretty-select>
            <span class="pretty-select-error" v-if="!$v.form.type.required">Field type is required</span>
        </div>

        <div :class="getValidationClass($v, 'default')">
            <div v-if="form.type === 'BOOLEAN'" class="mb-3">
                <p class="mb-1">Default value*</p>
                <span class="ml-n1 mr-2">
                    <input type="radio" id="TRUE" value="TRUE" v-model="form.default">
                    <label for="TRUE">TRUE</label>
                </span>
                <input type="radio" id="FALSE" value="FALSE" v-model="form.default">
                <label for="FALSE">FALSE</label>
            </div>
            <div v-else-if="form.type === 'DATE'">
                <mercur-date-picker v-model="form.default" singleDatePicker autoApply></mercur-date-picker>
            </div>
            <mercur-input v-model="form.default" v-else>
                Default Value*
            </mercur-input>
            <span class="form-error" v-if="!$v.form.default.required">Default value is required</span>
            <span class="form-error" v-if="!$v.form.default.isValid">Default value is not valid according to its type.</span>
        </div>

        <div class="mb-3">
            <mercur-checkbox v-model="form.array" :disabled="systemRestricted">Restrict possible options to: (define possible options below)</mercur-checkbox>
        </div>

        <template>
            <div v-if="form.array">
                <h4 class="mb-0">Values: </h4>
                <div class="mb-2">
                    <span :key="i" v-for="(value, i) in form.values">{{ value }}, </span>
                </div>
                <div class="d-flex align-items-end mb-3">
                    <div class="flex-grow-1">
                        <!-- If type is DATE -->
                        <mercur-date-picker v-model="fieldArrayValue" v-if="form.type === 'DATE'" singleDatePicker autoApply></mercur-date-picker>
                        <mercur-input class="mb-0" v-else v-model="fieldArrayValue">
                            Value
                        </mercur-input>
                        <span v-if="fieldArrayValue.length && !$v.fieldArrayValue.isValid" class="form-error">Value is not valid according to its type.</span>
                    </div>
                    <mercur-button class="btn btn-primary btn-form-adjusted ml-2" @click.native="addFieldValue">Add</mercur-button>
                </div>
                <div>
                    <mercur-checkbox v-if="form.array" v-model="form.multipleValues" :disabled="systemRestricted" id="multiallowed">Multiple values allowed</mercur-checkbox>
                </div>
            </div>
        </template>
        <div class="text-right">
            <mercur-button class="btn btn-primary" @click.native="emitValue">Add/Update Field</mercur-button>
        </div>
    </div>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators'
import PrettySelect from '../../../utils/PrettySelect'
import FormMixin from '@/mixins/Form'

export default {
    name: 'AccountConfigurationSchemaField',
    mixins: [FormMixin],
    components: { PrettySelect },
    props: {
        value: {
            default () {
                return {
                    name: '',
                    title: '',
                    type: '',
                    default: '',
                    array: false,
                    multipleValues: false,
                    values: [],
                }
            },
            required: false,
        },
        systemRestricted: {
            default: false,
        },
    },
    data () {
        return {
            fieldTypes: ['BOOLEAN', 'STRING', 'INTEGER', 'FLOAT', 'MONEY', 'NUMERIC', 'DATE'],
            form: {
                name: '',
                title: '',
                type: '',
                default: '',
                array: false,
                multipleValues: false,
                values: [],
            },
            fieldArrayValue: '',
            restrictMultipleValues: false,
        }
    },
    validations: {
        form: {
            name: {
                required,
                isCamelCase: value => /^([a-z]+)(([A-Z]([a-z]+))+)$/.test(value),
                minLength: minLength(2),
            },
            title: {
                required,
            },
            type: {
                required,
            },
            default: {
                required,
                isValid: function (value, form) {
                    if (form.type === 'INTEGER' || form.type === 'NUMERIC' || form.type === 'MONEY') {
                        return !(isNaN(value))
                    }
                    if (form.type === 'FLOAT') {
                        return (!isNaN(value) && value.toString().indexOf('.') !== -1)
                    }
                    return true
                },
            },
            values: {
                minLength: function () {
                    if (this.restrictMultipleValues === false) {
                        return true
                    }
                    return this.values.length > 0
                },
            },
        },
        fieldArrayValue: {
            isValid: function (value) {
                const { type } = this.form
                if (type === 'INTEGER' || type === 'NUMERIC' || type === 'MONEY') {
                    return !(isNaN(value))
                }
                if (type === 'FLOAT') {
                    return (!isNaN(value) && value.toString().indexOf('.') !== -1)
                }
                return true
            },
        },
    },
    watch: {
        restrictMultipleValues (val) {
            if (val === true) {
                this.form.values = []
            }
            if (val === false) {
                this.form.multipleValues = false
            }
        },
        'form.type': function (val, oldVal) {
            if (val !== oldVal) {
                this.form.values = []
            }
        },
    },
    methods: {
        emitValue () {
            this.$v.$reset()
            this.$v.$touch()
            if (this.$v.$invalid) {
                return
            }
            this.$emit('input', this.form)
        },
        addFieldValue () {
            this.$v.fieldArrayValue.$reset()
            this.$v.fieldArrayValue.$touch()

            if (this.$v.fieldArrayValue.$invalid) {
                return
            }

            this.form.values.push(this.fieldArrayValue)
            this.fieldArrayValue = ''
        },
    },
    created () {
        this.$set(this, 'form', JSON.parse(JSON.stringify(this.value)))
    },
}
</script>
